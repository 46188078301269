/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _dialogRef?: MatDialogRef<unknown, any>;
  private _subscription?: Subscription;

  constructor(private _dialog: MatDialog) {}

  handleDialogOpen = (
    component: ComponentType<unknown>,
    config?: MatDialogConfig<any>
  ) => {
    this._dialogRef = this._dialog.open(component, config);
    return this._dialogRef;
  };

  handleDialogClose = (callback?: (value: any) => void) => {
    if (this._dialogRef && callback) {
      this._dialogRef.afterClosed().subscribe(callback);
    }
  };

  handleDialogUnsubscribe = () => {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  };
}
