import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import {
    IFilterOffers,
    IOffer,
    IOfferStatus,
    IOffersResponse,
    OfferStatusType,
} from '@core/interfaces/offers/offers.interface';
import { IUser } from '@core/interfaces/users/user.interface';
import { IFoundation } from '@core/types/foundations';
import { IPensionFund } from '@core/types/pension-fund';
import { IPerson } from '@core/types/person';
import ISector from '@core/types/sector';
import IPlanSaving from '@core/types/planSavingName';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, tap, map } from 'rxjs';
import { environment } from '@environment/environment';

@Injectable({
    providedIn: 'root',
})
export class OffersService {
    private _offers$ = new Subject<IOffer[]>();
    private _offer$ = new Subject<IOffer>();
    private _status$ = new Subject<any>();
    private _people$ = new Subject<IPerson[]>();
    private _foundations$ = new Subject<IFoundation[]>();
    private _pensionFunds$ = new Subject<IPensionFund[]>();
    private _sectors$ = new Subject<ISector[]>();
    private _savingPlan$ = new Subject<IPlanSaving>();
    private _calculations$ = new Subject<any>();
    private _languageChange$ = new Subject<string>();
    public statusChangeMade = new EventEmitter<string>();
    public requestToUpdateAccessToFields = new EventEmitter<OfferStatusType>();
    public calculationRequested = new EventEmitter<boolean>();
    public reloadDocumentDataRequested = new EventEmitter<boolean>();
    public reloadReviewStepRequested = new EventEmitter<boolean>();
    public reloadCategoryRequested = new EventEmitter<boolean>();
    public offerCreated = new EventEmitter<IOffer>();

    get offers() {
        return this._offers$.asObservable();
    }

    get people() {
        return this._people$.asObservable();
    }
    get offer() {
        return this._offer$.asObservable();
    }
    constructor(private _http: HttpClient, private _translateService: TranslateService) {}
    get apiUrl(): string {
        // return 'http://dockerrun.claninfo.ch:5808/dataservice/offertool'
        // return 'http://localhost:5800/dataservice/offertool';
        return `${localStorage.getItem('api-url')}/offertool`;
    }
    setLanguage(language) {
        this._languageChange$.next(language);
    }
    get language$() {
        return this._languageChange$.asObservable();
    }
    getAllReviewers(offerId: number) {
        return this._http.get<IUser[]>(`${this.apiUrl}/users/reviewers/offer/${offerId}`);
    }
    setReviewer(offerId: number, reviewerId: number) {
        const body = { reviewerId };
        const path = `${this.apiUrl}/offers/${offerId}/reviewer`;
        return this._http.patch(path, body);
    }
    getOffers() {
        return this._http.get<IOffer[]>(`${this.apiUrl}/offers`).pipe(
            tap((offers) => {
                this._offers$.next(offers);
            })
        );
    }
    getOfferStatuses(offerId: number) {
        return this._http.get<IOfferStatus[]>(`${this.apiUrl}/offers/${offerId}/statuses`).pipe(
            tap((response) => {
                this._status$.next(response);
            })
        );
    }

    getStatus() {
        return this._http.get<any>(`${this.apiUrl}/offers/status`).pipe(
            tap((response) => {
                this._status$.next(response);
            })
        );
    }
    getFoundations() {
        return this._http.get<IFoundation[]>(`${this.apiUrl}/foundations`).pipe(
            tap((foundation) => {
                this._foundations$.next(foundation);
            })
        );
    }
    getFoundationById(foundationId: number) {
        return this._http.get<IFoundation>(`${this.apiUrl}/foundations/${foundationId}`).pipe(
            map((foundation) => [foundation]),
            tap((foundation) => {
                this._foundations$.next(foundation);
            })
        );
    }

    getPlanEditableById(offerId: number) {
        return this._http.get<boolean>(`${this.apiUrl}/offers/${offerId}/planEditable`);
    }

    getAllPensionFunds(foundationId?: number, pensionType?: string, enteredValue?: string) {
        let params = new HttpParams();

        if (foundationId) {
            params = params.set('foundationId', foundationId);
        }

        params = params.set('pensionFundType', pensionType ? pensionType : '');

        params = params.set('enteredValue', enteredValue ? enteredValue : '');

        const url = `${this.apiUrl}/pension-funds`;
        return this._http.get<IPensionFund[]>(url, { params }).pipe(
            tap((pensionFunds) => {
                this._pensionFunds$.next(pensionFunds);
            })
        );
    }
    getSavingsPlan(id: number) {
        return this._http.get<any>(`${this.apiUrl}/offers/${id}/savingPlans`);
    }
    setSavingsPlan(body, id) {
        return this._http.post<any>(`${this.apiUrl}/offers/${id}/savingPlans`, body);
    }
    getAllSectors() {
        return this._http.get<ISector[]>(`${this.apiUrl}/sectors`).pipe(
            tap((sectors) => {
                this._sectors$.next(sectors);
            })
        );
    }

    getAllOffers(page, sort, params: IFilterOffers) {
        const { offerName, ownerName, reviewerName, startDate, endDate, companyName, status } = params || {};

        const allParamValues: HttpParams = new HttpParams()
            .set('page', `${page?.pageIndex || 0}`)
            .set('size', `${page?.pageSize || 20}`)
            .set('sortColumn', `${sort?.sortColumn || 'id'}`)
            .set('sortDirection', `${sort?.sortDirection || 'desc'}`)
            .set('offerName', offerName || '')
            .set('ownerName', ownerName || '')
            .set('reviewerName', reviewerName || '')
            .set('startDate', startDate || '')
            .set('endDate', endDate || '')
            .set('companyName', companyName || '')
            .set('status', status || '');
        const options = {
            params: allParamValues,
        };
        return this._http.get<IOffersResponse>(`${this.apiUrl}/offers`, options);
    }
    getOffer(id: number) {
        return this._http.get<IOffer>(`${this.apiUrl}/offers/${id}`);
    }

    createOffer(): Observable<IOffer> {
        return this._http.post<IOffer>(`${this.apiUrl}/offers`, {});
    }

    createPerson(offerId: string, personalData: IPerson): Observable<IOffer> {
        return this._http.post<IOffer>(`${this.apiUrl}/offers/${offerId}/persons`, [personalData]);
    }

    updatePerson(offerId: string, personalData: IPerson): Observable<IOffer> {
        return this._http.put<IOffer>(`${this.apiUrl}/offers/${offerId}/persons/${personalData.id}`, personalData);
    }

    getPerson(offerId: string, personId: string): Observable<IPerson> {
        return this._http.get<IPerson>(`${this.apiUrl}/offers/${offerId}/persons/${personId}`);
    }

    deletePerson(id, personId) {
        const path = `${this.apiUrl}/offers/${id}/persons/${personId}`;
        return this._http.delete(path);
    }

    deleteAllPersons(id) {
        const path = `${this.apiUrl}/offers/${id}/persons`;
        return this._http.delete(path);
    }

    downloadExampleCsv() {
        const fileName = this._translateService.instant('cabinet.fileupload.exampleFileDownload');
        const filePath = `assets/${fileName}`;
        return this._http.get(filePath, { responseType: 'blob' });
    }

    updateOffer(offer: any, id?: any) {
        const offerId = offer?.id ? offer.id : id;
        return this._http.put<any>(`${this.apiUrl}/offers/${offerId}`, offer);
    }

    deleteOffer(id: number) {
        return this._http.delete<IOffer[]>(`${this.apiUrl}/offers/${id}`);
    }
    getAllPersons<T>(id, page?, column?, direction?) {
        const paramsInfo = {
            sortColumn: `sortColumn=${column || ''}`,
            sortDirection: `sortDirection=${direction || ''}`,
            pageNumber: `page=${page?.pageIndex || 0}`,
            pageSize: `size=${page?.pageSize || 20}`,
        };
        const path = `${this.apiUrl}/offers/${id}/persons?${paramsInfo.sortColumn}&${paramsInfo.sortDirection}&${paramsInfo.pageNumber}&${paramsInfo.pageSize}`;
        return this._http.get<any>(path).pipe(
            tap((people) => {
                this._people$.next(people);
            })
        );
    }

    getBenefits<T>(id, page?, column?, direction?) {
        const paramsInfo = {
            sortColumn: `sortColumn=${column || ''}`,
            sortDirection: `sortDirection=${direction || ''}`,
            pageNumber: `page=${page?.pageNumber || 0}`,
            pageSize: `size=${page?.pageSize || 10}`,
        };

        const path = `${this.apiUrl}/offers/${id}/benefits?${paramsInfo.sortColumn}&${paramsInfo.sortDirection}&${paramsInfo.pageNumber}&${paramsInfo.pageSize}`;
        return this._http.get<any>(path).pipe(
            tap((people) => {
                this._people$.next(people);
            })
        );
    }
    getContribution<T>(id, page?, column?, direction?) {
        const paramsInfo = {
            sortColumn: `sortColumn=${column || ''}`,
            sortDirection: `sortDirection=${direction || ''}`,
            pageNumber: `page=${page?.pageNumber || 0}`,
            pageSize: `size=${page?.pageSize || 10}`,
        };

        const path = `${this.apiUrl}/offers/${id}/contributions?${paramsInfo.sortColumn}&${paramsInfo.sortDirection}&${paramsInfo.pageNumber}&${paramsInfo.pageSize}`;
        return this._http.get<any>(path).pipe(
            tap((people) => {
                this._people$.next(people);
            })
        );
    }

    insertPersons(persons, id) {
        const body = persons;
        const path = `${this.apiUrl}/offers/${id}/persons`;
        return this._http.post(path, body);
    }

    renameCategories(categoriesRename, id) {
        return this._http.post(`${this.apiUrl}/offers/${id}/categoryRename`, categoriesRename);
    }

    getUsedCategories(id) {
        return this._http.get<number[]>(`${this.apiUrl}/offers/${id}/categoryRename`);
    }

    getOffersCalculation(id: number) {
        return this._http.get(`${this.apiUrl}/offers/${id}/summaries`).pipe(
            tap((calculations) => {
                this._calculations$.next(calculations);
            })
        );
    }
    changeOwner(offerId: number, status: OfferStatusType, owner_id?: number) {
        return this._http.post(`${this.apiUrl}/offers/${offerId}/proceed`, {
            statusName: status,
            remark: 'action: change owner',
            newOwnerId: owner_id,
        });
    }
    proceedToStatus(offerId: number, status: OfferStatusType, remark?: string, reviewer_id?: number) {
        return this._http.post(`${this.apiUrl}/offers/${offerId}/proceed`, {
            statusName: status,
            remark: remark,
            reviewerId: reviewer_id,
            language: this._translateService.currentLang,
        });
    }
    copyOffer(id: number, body: IOffer) {
        return this._http.post(`${this.apiUrl}/offers/copy/${id}`, body);
    }
    getOfferForExport(id: number) {
        return this._http.get<IOffer>(`${this.apiUrl}/offers/${id}/export`).pipe(
            tap((offer) => {
                this._offer$.next(offer);
            })
        );
    }
}
